import React, { useState } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import "../../assets/css/global.css"
import { Link } from "gatsby"
import { navigate } from 'gatsby-link'
import Calendar from "../../assets/images/Calendar-Light.svg"
import Film from "../../assets/images/Film-Dark.svg"
import Seo from "../../components/Seo"
import {Helmet} from "react-helmet"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const SubmitLibrary = () => {
  const [state, setState] = useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
    return (
      <Layout>
        <Helmet>
        <meta charSet="utf-8"/>
        <title>Submit to our Film Library</title>
        <link rel="canonical" href="https://filmchronicle.com/submissions/film-library" />
      </Helmet>
        <Seo title="Submit to our Film Library" description="Accepting current and past student films" image="../../../assets/images/Submissions__Film-Library-Hero.png" />
        <header className="hero">
        <StaticImage
            src="../../assets/images/Submissions__Film-Library-Hero.png"
            alt="flour and eggs"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
          <div className="hero-container dark-overlay">
          <div className="hero-text">
            <h1 style={{marginBottom: "1.5rem"}}>Submission Form</h1>
          </div>
        </div>
        </header>
        <div className="container-md">
          <div className="grid-cols-2 center" style={{justifyItems: "center", paddingTop: "50px"}}>
          <Link to="/submissions/monthly-festival">
              <img
                src={Calendar}
                alt="icon of a calendar"
                className="hero-img"
                style={{margin: "auto", paddingBottom: "25px"}}
                placeholder="tracedSVG"
              />
              <h3 style={{textAlign: "center"}}>SUBMIT TO FESTIVAL</h3>
            </Link>
          <Link to="/submissions/film-library">
            <img
              src={Film}
              alt="icon of a film"
              className="hero-img"
              placeholder="tracedSVG"
              style={{fontSize: "100px", margin: "auto", paddingBottom: "25px"}}
            />
            <h3 style={{textAlign: "center"}}>SUBMIT TO LIBRARY</h3>
            <div style={{paddingTop: "15px",borderBottom: "0.25rem solid red"}}>
              </div>
          </Link>
          </div>
        </div>
        <div style={{paddingTop: "30px", backgroundColor: "#B2B8BF", borderRadius: "8px", marginBottom: "5rem"}} className="container-md">
        <h4 style={{fontWeight: "600", marginBottom: "1.5rem"}}>About You</h4>
        <form name="contact" enctype="multipart/form-data" method="post" action="/submissions/what-now" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact"/>
          <p hidden>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Don't fill this out: <input name="bot-field" onChange={handleChange}/></label>   
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Full Name <input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="name" onChange={handleChange}/></label>   
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>School Email <input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="email" name="email" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Name of University, College, or Educational Program*<input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="university" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "75px"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Graduation Year<input style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="grad_year" onChange={handleChange}/></label>
          </p>
          <h4 style={{fontWeight: "600", marginBottom: "1.5rem"}}>About Your Film</h4>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Title <input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="title" onChange={handleChange}/></label>   
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Genre
            <select required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} onChange={handleChange} name="genre">
              <option selected>Select</option>
              <option value="action">Action</option>
              <option value="adventure">Adventure</option>
              <option value="animation">Animation</option>
              <option value="children">Children</option>
              <option value="comedy">Comedy</option>
              <option value="crime">Crime</option>
              <option value="documentary">Documentary</option>
              <option value="drama">Drama</option>
              <option value="experimental">Experimental</option>
              <option value="fantasy">Fantasy</option>
              <option value="horror">Horror</option>
              <option value="musical">Musical</option>
              <option value="music-video">Music Video</option>
              <option value="mystery">Mystery</option>
              <option value="romance">Romance</option>
              <option value="sci-fi">Sci-Fi</option>
              <option value="thriller">Thriller</option>
              <option value="western">Western</option>
              <option value="other">Other</option>
            </select> 
            </label>  
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Downloadable Link - e.g. Vimeo, YouTube, etc. Your submission will be uploaded to the Film Chronicle video account.<input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="url" name="video_url" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Thumbnail (max size: 8 MB)<input type="file" style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} name="thumbnail" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Language *<input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="language" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Production Date<input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="production_date" onChange={handleChange}/></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Film Duration<input required style={{width: "100%", borderRadius: "8px", height: "3rem", fontSize: "24px", paddingLeft: "5px"}} type="text" name="duration" onChange={handleChange}></input></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Storyline (max 280 characters) <textarea required style={{width: "100%", borderRadius: "8px", height: "6rem", fontSize: "24px", paddingLeft: "5px"}} name="storyline" onChange={handleChange}></textarea></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Cast <textarea required style={{width: "100%", borderRadius: "8px", height: "6rem", fontSize: "24px", paddingLeft: "5px"}} name="cast" onChange={handleChange}></textarea></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Crew <textarea required style={{width: "100%", borderRadius: "8px", height: "6rem", fontSize: "24px", paddingLeft: "5px"}} name="crew" onChange={handleChange}></textarea></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{fontWeight: "500", fontSize: "16px", marginBottom: "1.5rem"}}>Technical Specifications (camera, aspect ratio, sound) <textarea required style={{width: "100%", borderRadius: "8px", height: "6rem", fontSize: "24px", paddingLeft: "5px"}} name="tech_specs" onChange={handleChange}></textarea></label>
          </p>
          <h4 style={{fontWeight: "600", marginBottom: "1.5rem"}}>Confirmation &amp; Submit</h4>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{display: "block", paddingLeft: "15px", textIndent: "-15px", fontWeight: "500", fontSize: "16px", verticalAlign: "center"}}><input required style={{borderRadius: "8px", width: "15px", height: "15px", padding: "0", margin: "0", verticalAlign: "center", position: "relative"}} type="checkbox" name="rules" onChange={handleChange}></input>I acknowledge that I have read and accepted the Film Chronicle Monthly Festival <Link style={{textDecoration: "underline"}} to="/about/rules/">Rules</Link></label>
          </p>
          <p style={{marginBottom: "0.5rem"}}>
            <label style={{display: "block", paddingLeft: "15px", textIndent: "-15px", fontWeight: "500", fontSize: "16px", verticalAlign: "center"}}><input required style={{borderRadius: "8px", width: "15px", height: "15px", padding: "0", margin: "0", verticalAlign: "center", position: "relative", top: "-1px"}} type="checkbox" name="rules" onChange={handleChange}></input>Receive a copy of your submission</label>
          </p>
          <p style={{paddingBottom: "15px"}}>
            <button className="btn btn-black center-btn" type="submit">Finish &amp; submit</button>
          </p>
        </form>
        </div>
      </Layout>
    )
  }
  
  export default SubmitLibrary